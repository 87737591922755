import { IWorkflowHistory } from 'types';

export const DISPLAYED_COLUMN_KEYS: Array<keyof IWorkflowHistory> = [
  'workflow_name',
  'version',
  'workflow',
  'valid',
  'active',
  'author',
  'create_time',
];
export const PAGE_SIZE = 20;
export const PAGE_SIZES = [10, 20, 50];
