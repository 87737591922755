import { ICellValue, IColumn } from 'react-ui-kit-exante';

import type { TWorkflow } from 'types';

import { EDITABLE_WORKFLOW_LIST } from './constants';
import { IconRight, TtlText } from './styled';

export const getColumns = (): IColumn<TWorkflow>[] => [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Auto Decline In',
    accessor: 'ttl',
    formatting: 'number',
    align: 'left',
    editParams: {
      editable: true,
      inputType: 'text',
      additionalInputParams: {
        iconRight: <IconRight>day(s)</IconRight>,
        type: 'number',
      },
    },
    Cell: ({ row }: ICellValue<TWorkflow>) => {
      const { ttl, wf_type: wfType } = row.values;

      if (!ttl) {
        return null;
      }

      return (
        <TtlText editable={EDITABLE_WORKFLOW_LIST.includes(wfType)}>
          {ttl} day(s)
        </TtlText>
      );
    },
  },
  {
    Header: 'Version',
    accessor: 'version',
  },
  {
    Header: 'Type',
    accessor: 'wf_type',
  },
  {
    Header: 'Valid',
    accessor: 'valid',
    disableSortBy: true,
  },
  {
    Header: 'Active',
    accessor: 'active',
    disableSortBy: true,
  },
  {
    Header: 'Hash',
    accessor: 'hash_sum',
    width: 320,
    disableSortBy: true,
  },
  {
    Header: 'Errors',
    disableSortBy: true,
    accessor: 'validation_errors',
  },
  {
    Header: 'Author',
    accessor: 'author',
  },
  {
    Header: 'Created',
    accessor: 'create_time',
    formatting: 'date',
  },
];
