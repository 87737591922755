import { IApprovalSetting, ICreateApprovalSettingPayload } from 'types';
import { apiRequest } from 'utils';

import { APPROVAL_SETTING_API, getApprovalSettingById } from './endpoints';

export class ApprovalSettingRepository {
  public static fetchApprovalSettings() {
    return apiRequest<IApprovalSetting[]>({
      url: APPROVAL_SETTING_API,
    });
  }

  public static updateApprovalSetting(payload: IApprovalSetting) {
    return apiRequest<IApprovalSetting>({
      url: APPROVAL_SETTING_API,
      method: 'PUT',
      data: payload,
    });
  }

  public static deleteApprovalSetting(id: string) {
    return apiRequest({
      url: getApprovalSettingById(id),
      method: 'DELETE',
    });
  }

  public static createApprovalSetting(payload: ICreateApprovalSettingPayload) {
    return apiRequest<IApprovalSetting>({
      url: APPROVAL_SETTING_API,
      method: 'POST',
      data: payload,
    });
  }
}
