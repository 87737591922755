import type { TParams, TUpdateWorkflowParams } from 'types';
import { apiRequest } from 'utils';

import { WORKFLOW_PARAMS_UPDATE_API } from './endpoints';

export class WorkflowParamsRepository {
  public static updateWorkflowParams(
    params: TParams,
    payload: TUpdateWorkflowParams,
  ) {
    return apiRequest({
      params,
      url: WORKFLOW_PARAMS_UPDATE_API,
      method: 'PUT',
      data: payload,
    });
  }
}
