import { merge } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { createTheme, ThemeProvider } from 'react-ui-kit-exante';

import { theme } from './defaultTheme';

type ComposedThemeProviderProps = {
  children: ReactNode;
};

export const ComposedThemeProvider = ({
  children,
}: ComposedThemeProviderProps) => {
  const brandingTheme = window.WORKFLOW_UI?.theme;

  const themeValue = useMemo(() => {
    if (!brandingTheme) {
      return theme;
    }

    return createTheme(merge({}, theme, brandingTheme));
  }, [brandingTheme]);

  return <ThemeProvider theme={themeValue}>{children}</ThemeProvider>;
};
