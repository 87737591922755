import { Panel, styled } from 'react-ui-kit-exante';

export const PanelContainer = styled(Panel)(({ theme }) => ({
  borderRight: `1px ${theme.color.input.border} solid`,
  minWidth: '430px',
}));

export const Title = styled('h2')(() => ({
  fontSize: '32px',
  fontWeight: 400,
  margin: '0 0 24px',
}));

export const CardRow = styled('div')(() => ({
  fontSize: '13px',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
}));

export const FeeSetup = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  height: '100%',
  width: '100%',
}));
