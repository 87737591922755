import { FC } from 'react';
import { CronScheduler, Panel } from 'react-ui-kit-exante';

import { ActionsApproveDecline } from 'components/ActionsApproveDecline';
import { Card } from 'components/Card';
import { InfoNotifications } from 'components/InfoNotifications';
import { WfType } from 'constants/index';
import type {
  IExecutionDetails,
  TInfoCombined,
  TUpdateStepComment,
} from 'types';

import { FeeSetup } from './FeeSetup';
import { FeeSetupUser } from './FeeSetupUser';
import { Steps } from './Steps/Steps';
import { getInfo } from './helpers/getInfo';
import { SchedulerBlock, TableWrapper, Wrapper } from './styled';

interface IExecutionContainerProps {
  data: IExecutionDetails;
  onApproveExecution: (value: string) => void;
  onDeclineExecution: (value: string) => void;
  onRestartWorkflow: () => void;
  updateStepComment: (payload: TUpdateStepComment) => void;
}

export const ExecutionContainer: FC<IExecutionContainerProps> = ({
  data,
  onApproveExecution,
  onDeclineExecution,
  onRestartWorkflow,
  updateStepComment,
}) => {
  const {
    execution_id: executionId,
    info,
    steps,
    wf_type: wfType,
    workflow,
  } = data;
  const isInfo = info?.length > 0;
  const cardTitle = isInfo ? 'Info' : 'Input';
  const { formattedInfo, formattedInput, schedule } = getInfo({ data });
  const cardData: TInfoCombined = isInfo ? formattedInfo : formattedInput;
  let content = (
    <Steps
      executionId={executionId}
      steps={steps}
      updateStepComment={updateStepComment}
    />
  );

  if (wfType === WfType.FEE_SETUP) {
    content = <FeeSetup data={data} />;
  } else if (wfType === WfType.FEE_SETUP_USER) {
    content = <FeeSetupUser data={data} />;
  }

  return (
    <>
      <Panel
        title={workflow}
        action={
          <>
            <InfoNotifications
              data={data}
              onRestartWorkflow={onRestartWorkflow}
            />
            <ActionsApproveDecline
              onDeclineExecution={onDeclineExecution}
              onApproveExecution={onApproveExecution}
              data={data}
            />
          </>
        }
      />
      <Wrapper>
        <Card title={cardTitle} data={cardData} />
        <TableWrapper>
          {schedule && (
            <SchedulerBlock title="Schedule">
              <CronScheduler
                value={schedule}
                setValue={() => {}}
                clearButton
                readOnly
              />
            </SchedulerBlock>
          )}
          {content}
        </TableWrapper>
      </Wrapper>
    </>
  );
};
