import dayjs from 'dayjs';
import { FC } from 'react';
import { Tooltip } from 'react-ui-kit-exante';

import { DateFormats } from 'constants/dateFormats';
import { KEYS } from 'constants/index';

import { isValueType } from './helpers';
import {
  CardRow,
  KeyContainer,
  PanelContainer,
  TitleContainer,
  Value,
  ValueContainer,
} from './styled';
import { ICardNewProps } from './types';

export const Card: FC<ICardNewProps> = ({ title, data }) => {
  return Object.keys(data).length ? (
    <PanelContainer>
      <TitleContainer>{title}</TitleContainer>
      {Object.entries(data).map(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          return (
            <CardRow key={key}>
              <KeyContainer>{key}</KeyContainer>
              <ValueContainer>{value.join(', ')}</ValueContainer>
            </CardRow>
          );
        }

        if (KEYS.includes(key)) {
          return (
            <CardRow key={key}>
              <KeyContainer>{key}</KeyContainer>
              <ValueContainer>
                {dayjs(value).format(DateFormats.DDMMYYYYHHMM)}
              </ValueContainer>
            </CardRow>
          );
        }

        return (
          isValueType(value) &&
          value.toString() && (
            <CardRow key={key}>
              <KeyContainer>{key}</KeyContainer>
              <ValueContainer>
                <Tooltip title={value.toString()}>
                  <Value>{value.toString()}</Value>
                </Tooltip>
              </ValueContainer>
            </CardRow>
          )
        );
      })}
    </PanelContainer>
  ) : null;
};
