import { Link } from 'react-router-dom';
import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

type ActiveLinkProps = {
  pageName: boolean;
};

export const Container = styled('header')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.success.main,
  color: theme.color.typo.inverse,
  display: 'flex',
  fontSize: '18px',
  fontWeight: 600,
  justifyContent: 'space-between',
  padding: '15px 24px',
}));

export const Version = styled('span')(() => ({
  fontSize: '12px',
  fontWeight: 500,
}));

export const LinkStyled = styled(Link, {
  shouldForwardProp: blockNonNativeProps(['pageName']),
})<ActiveLinkProps>(({ pageName, theme }) => ({
  color: pageName ? theme.color.typo.primary : theme.color.typo.inverse,
  marginRight: '20px',
  textDecoration: 'unset',
}));
