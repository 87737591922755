import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ICellValue, useTableData } from 'react-ui-kit-exante';

import { DEFAULT_POOLING_INTERVAL_DATA_20 } from 'constants/common';
import { useInterval, useLogHandleTime, usePrevious } from 'hooks';
import { executionService } from 'resources';
import {
  clearSelectedExecutionAction,
  selectedExecutionAction,
} from 'store/executions';
import { selectWorkflow } from 'store/workflow';
import type { IExecutionData, TExecution } from 'types';
import {
  calculateCountOfPages,
  getThreeDaysDefaultFilter,
  prepareTableId,
} from 'utils';

import { getColumns } from '../columns';
import { getDefaultPagination, getDefaultSorting } from '../constants';
import { getAdditionalFilters } from '../filters';
import { TGetExecutionsProps } from '../types';

export const useExecutionsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableId = prepareTableId('executions');
  const { data: workflowOptions } = useSelector(selectWorkflow);
  const [isError, setIsError] = useState(false);

  const { logHandleTime, setStartHandleTime } =
    useLogHandleTime('executions-page');

  const getExecutions = useCallback(
    ({ params: { skip, ...rest } }: TGetExecutionsProps) => {
      setStartHandleTime();

      return executionService.fetchExecutions(
        {
          ...rest,
          offset: skip,
        },
        setIsError,
      );
    },
    [setStartHandleTime],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getExecutions },
      pagination: {
        getDefaultPagination,
      },
      sorting: { getDefaultSorting },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getExecutions, tableId],
  );

  const {
    data,
    fetchData: refetch,
    filters,
    isLoading,
    limit,
    page,
    removeFilter,
    resetFilters,
    setFilter,
    setLimit,
    setPage,
    setSorting,
  } = useTableData<IExecutionData>(tableDataArgs);

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        workflowOptions,
      }),
    [setFilter, removeFilter, workflowOptions],
  );
  const total = data?.count || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        filters,
        onFilter: setFilter,
        onRemove: removeFilter,
        defaultFilters: getThreeDaysDefaultFilter(),
      }),
    [filters, removeFilter, setFilter],
  );

  const filteringProps = useMemo(
    () => ({
      additionalFilters,
      filters,
      manualFilters: true,
      removeAllFilters: resetFilters,
    }),
    [additionalFilters, filters, resetFilters],
  );

  const getRowProps = () => ({
    style: {
      cursor: 'pointer',
    },
  });

  const handleCellClick = useCallback(
    ({ row: { original } }: ICellValue<TExecution>) => {
      if (original.execution_id) {
        dispatch(selectedExecutionAction(original));

        navigate(`/wfe/executions/${original.execution_id}`, {
          state: {
            locationInfo: !!original.info?.length,
            locationWfType: original.wf_type,
            locationWorkflow: original.workflow,
          },
        });
      }
    },
    [navigate],
  );

  const serverPaginationProps = useMemo(
    () => ({
      total,
      setPage,
      pageCount,
      pageSize: limit,
      pageIndex: page,
      setPageSize: setLimit,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const previousExecutions = usePrevious(data?.results);

  useEffect(() => {
    dispatch(clearSelectedExecutionAction());
  }, []);

  const clearInterval = useInterval(
    () => refetch(),
    DEFAULT_POOLING_INTERVAL_DATA_20,
  );

  useEffect(() => {
    if (isError) {
      clearInterval();
    }
  }, [isError]);

  useEffect(() => {
    if (data?.results && !isEqual(previousExecutions, data.results)) {
      logHandleTime();
    }
  }, [data, logHandleTime, previousExecutions]);

  return {
    columns,
    data,
    filteringProps,
    isLoading,
    getRowProps,
    handleCellClick,
    serverPaginationProps,
    setSorting,
    tableId,
  };
};
