import { IColumn } from 'react-ui-kit-exante';

import { IApprovalGroup } from 'types';

export const getColumns = (): IColumn<IApprovalGroup>[] => [
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Name',
    accessor: 'name',
    editParams: {
      editable: false,
    },
  },
  {
    Header: 'Description',
    accessor: 'description',
    editParams: {
      editable: true,
      inputType: 'textArea',
    },
  },
];
