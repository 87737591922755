import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Panel, Skeleton, Table } from 'react-ui-kit-exante';

import { WfType } from 'constants/index';
import { prepareTableId } from 'utils';

import { getColumns } from '../Steps/columns';
import { DISPLAYED_COLUMN_KEYS } from '../constants';
import { TableWrapper, Wrapper } from '../styled';

import { INITIAL_LOCATION_STATE } from './constants';
import { CardRow, FeeSetup, PanelContainer, Title } from './styled';
import { TLocationStateProps } from './types';

export const ExecutionSkeleton = () => {
  const { state = {} } = useLocation();
  const { locationInfo, locationWfType } = (state ||
    INITIAL_LOCATION_STATE) as TLocationStateProps;
  const tableId = prepareTableId('executionDetailsSkeleton');
  const columns = useMemo(() => getColumns(), []);
  const tableClassName = 'TextAlignment';
  const isFeeSetupUser =
    locationWfType === WfType.FEE_SETUP ||
    locationWfType === WfType.FEE_SETUP_USER;

  return (
    <>
      <Panel
        title={<Skeleton variant="text" width={350} height={50} />}
        action={<Skeleton variant="rounded" width={250} height={53} />}
      />
      <Wrapper>
        {locationInfo && (
          <PanelContainer>
            <Title>Info</Title>
            <CardRow key="key1">
              <Skeleton variant="text" width={100} height={20} />
              <Skeleton variant="text" width={50} height={20} />
            </CardRow>
            <CardRow key="key2">
              <Skeleton variant="text" width={120} height={20} />
              <Skeleton variant="text" width={90} height={20} />
            </CardRow>
            <CardRow key="key3">
              <Skeleton variant="text" width={90} height={20} />
              <Skeleton variant="text" width={70} height={20} />
            </CardRow>
            <CardRow key="key4">
              <Skeleton variant="text" width={90} height={20} />
              <Skeleton variant="text" width={60} height={20} />
            </CardRow>
            <CardRow key="key5">
              <Skeleton variant="text" width={80} height={20} />
              <Skeleton variant="text" width={60} height={20} />
            </CardRow>
            <CardRow key="key6">
              <Skeleton variant="text" width={90} height={20} />
              <Skeleton variant="text" width={100} height={20} />
            </CardRow>
            <CardRow key="key7">
              <Skeleton variant="text" width={150} height={20} />
              <Skeleton variant="text" width={50} height={20} />
            </CardRow>
            <CardRow key="key8">
              <Skeleton variant="text" width={130} height={20} />
              <Skeleton variant="text" width={30} height={20} />
            </CardRow>
            <CardRow key="key9">
              <Skeleton variant="text" width={110} height={20} />
              <Skeleton variant="text" width={60} height={20} />
            </CardRow>
            <CardRow key="key10">
              <Skeleton variant="text" width={170} height={20} />
              <Skeleton variant="text" width={60} height={20} />
            </CardRow>
          </PanelContainer>
        )}
        <TableWrapper>
          {isFeeSetupUser ? (
            <FeeSetup>
              <Skeleton variant="rounded" height={800} />
            </FeeSetup>
          ) : (
            <Table
              isLoading
              data={[]}
              title="Steps"
              isFlexLayout
              showTableInfo
              hasPagination
              pageSize={100}
              columns={columns}
              tableId={tableId}
              className={tableClassName}
              pageSizes={[20, 50, 100]}
              displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
            />
          )}
        </TableWrapper>
      </Wrapper>
    </>
  );
};
