import { styled } from 'react-ui-kit-exante';

export const Wrapper = styled('div')(({ theme }) => ({
  background: theme.color.bg.primary,
  padding: '0 24px 24px',
}));

export const ValueContainer = styled('div')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '20px',
  paddingBottom: '2px',
}));

export const LabelContainer = styled('div')(({ theme }) => ({
  color: theme.color.typo.secondary,
  fontSize: '13px',
}));
