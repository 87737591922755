import { Panel, styled } from 'react-ui-kit-exante';

export const PanelContainer = styled(Panel)(({ theme }) => ({
  borderRight: `1px ${theme.color.input.border} solid`,
  minWidth: '430px',
}));

export const TitleContainer = styled('h2')(() => ({
  fontSize: '32px',
  fontWeight: 400,
  margin: '0 0 24px',
}));

export const CardRow = styled('div')(() => ({
  fontSize: '13px',
  display: 'flex',
  gap: '16px',
  justifyContent: 'space-between',
  marginBottom: '10px',
}));

export const KeyContainer = styled('div')(({ theme }) => ({
  color: theme.color.typo.promo,
  display: 'flex',
}));

export const ValueContainer = styled('div')(() => ({
  display: 'flex',
  overflow: 'hidden',
}));

export const Value = styled('div')(() => ({
  display: 'inline-block',
  fontWeight: 600,
  maxWidth: '200px',
  overflow: 'hidden',
  textAlign: 'end',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));
