import { AxiosError } from 'axios';
import { Notification } from 'react-ui-kit-exante';

import { EMPTY_OBJECT } from 'constants/common';
import type { TAccumulationMarketValuePayload } from 'types';
import { getErrorMessage } from 'utils';

import { AccumMarketValueRepository } from './accumMarketValue.repository';

export class AccumMarketValueService {
  public async fetchMarketValue(
    payload: TAccumulationMarketValuePayload,
    setError?: (val: boolean) => void,
  ) {
    try {
      const { data } = await AccumMarketValueRepository.fetchMarketValue(
        payload,
      );

      return data;
    } catch (error: unknown) {
      if (
        [403, 401].includes((error as AxiosError).response?.status ?? 0) &&
        setError
      ) {
        setError(true);
      }
      Notification.error({
        title: getErrorMessage(
          error,
          'While fetching accumulation market value occurred error',
        ),
      });

      return EMPTY_OBJECT;
    }
  }
}
