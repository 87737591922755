import { formatNumber, Skeleton } from 'react-ui-kit-exante';

import { LabelContainer, ValueContainer, Wrapper } from './styled';

interface IMarketValueProps {
  marketValue: number;
  isLoading: boolean;
  isFetching: boolean;
}

export const MarketValue = ({
  marketValue,
  isLoading = false,
  isFetching = false,
}: IMarketValueProps) => {
  return (
    <Wrapper>
      <ValueContainer>
        {isLoading || isFetching ? (
          <Skeleton variant="text" width={100} height={50} />
        ) : (
          formatNumber(marketValue)
        )}
      </ValueContainer>
      <LabelContainer>Market Value, EUR</LabelContainer>
    </Wrapper>
  );
};
