import { IApprovalSetting } from 'types';

export const DISPLAYED_COLUMN_KEYS: Array<keyof IApprovalSetting> = [
  'approvalGroup',
  'approvalThreshold',
  'currency',
  'approvers',
  'emails',
  'actions',
];
export const PAGE_SIZE = 20;
export const PAGE_SIZES = [10, 20, 50];
