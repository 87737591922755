import { AxiosError } from 'axios';
import { Notification } from 'react-ui-kit-exante';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'constants/common';
import type {
  TUpdateStepComment,
  IExecutionData,
  TParams,
  TApproveExecution,
  TDeclineExecution,
} from 'types';
import { getErrorMessage, sendUiKitErrorNotification } from 'utils';

import { executionMappers } from './execution.mappers';
import { ExecutionRepository } from './execution.repository';
import { transformParams } from './helpers';

export class ExecutionService {
  public async fetchExecutions(
    params: TParams,
    setError: (value: boolean) => void,
  ): Promise<IExecutionData> {
    setError(false);
    try {
      const { data } = await ExecutionRepository.fetchExecutions(
        transformParams(params),
      );

      return {
        results: data.results,
        count: data.count,
      };
    } catch (error: unknown) {
      if ([403, 401].includes((error as AxiosError).response?.status ?? 0)) {
        setError(true);
      }
      Notification.error({
        title: getErrorMessage(
          error,
          'While fetching executions occurred error',
        ),
      });

      return {
        results: EMPTY_ARRAY,
        count: 0,
      };
    }
  }

  public async fetchExecution(id: string) {
    try {
      const { data } = await ExecutionRepository.fetchExecution(id);

      return executionMappers(data);
    } catch (err) {
      sendUiKitErrorNotification(err);

      return EMPTY_OBJECT;
    }
  }

  public async updateStepComment(payload: TUpdateStepComment): Promise<void> {
    try {
      await ExecutionRepository.updateStepComment(payload);

      Notification.success({
        title: 'Step comment successfully updated',
      });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }

  public async approveExecution(payload: TApproveExecution): Promise<void> {
    try {
      await ExecutionRepository.approveExecution(payload);

      Notification.success({
        title: 'Execution successfully approved',
      });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }

  public async declineExecution(payload: TDeclineExecution): Promise<void> {
    try {
      await ExecutionRepository.declineExecution(payload);

      Notification.success({
        title: 'Execution successfully declined',
      });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }

  public async restartExecution(id: string): Promise<void> {
    try {
      await ExecutionRepository.restartExecution(id);

      Notification.success({
        title: 'Workflow successfully restarted',
      });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }
}
