import { useParams } from 'react-router-dom';
import { Panel, Skeleton } from 'react-ui-kit-exante';

import { useGetWorkflowByHashSumQuery } from 'store/workflowByHashSum';
import type { IUseQueryReturn, TWorkflow } from 'types';

import { LinkTag, LinkWrapper, PreWrapper } from './styled';

type TWorkflowDetailsParamsProps = {
  hashSum: string;
};

export const WorkflowDetailsPage = () => {
  const { hashSum } = useParams<
    keyof TWorkflowDetailsParamsProps
  >() as TWorkflowDetailsParamsProps;

  const { data, isLoading, isFetching } =
    useGetWorkflowByHashSumQuery<IUseQueryReturn<TWorkflow>>(hashSum);
  const {
    json_field: jsonField = undefined,
    name,
    wf_type: wfType = undefined,
  } = data || {};

  const renderHead = () =>
    isLoading || isFetching ? (
      <Skeleton
        variant="rounded"
        width={350}
        height={40}
        sx={{ marginBottom: '8px' }}
      />
    ) : (
      name
    );

  let codeEditorValue;

  try {
    codeEditorValue = JSON.stringify(jsonField, null, 4);
  } catch (err) {
    console.error('Error occurred while trying to stringify jsonField');
  }

  return (
    <div>
      <Panel title={renderHead()}>
        <LinkWrapper>
          <LinkTag to={`/wfe/history?workflow=${wfType}`}>History</LinkTag>
          <LinkTag to={`/wfe/executions?workflow=${wfType}`}>
            Executions
          </LinkTag>
        </LinkWrapper>
      </Panel>
      <PreWrapper>{codeEditorValue}</PreWrapper>
    </div>
  );
};
