import { ISortBy } from 'react-ui-kit-exante';

export const DISPLAYED_COLUMN_KEYS = [
  'workflow',
  'status',
  'fail_reason',
  'create_time',
  'author',
];
export const PAGE_SIZE = 20;
export const PAGE_SIZES = [10, 20, 50];

export function getDefaultSorting(): ISortBy[] {
  return [{ id: 'update_time', desc: true }];
}
export const DEFAULT_SORTING = getDefaultSorting();

export const getDefaultPagination = () => ({
  limit: PAGE_SIZE,
  skip: 0,
});

export const WORKFLOW_OPTION_RESET = { value: '', label: 'Reset' };
