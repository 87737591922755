import { WfType } from 'constants/index';

import { EXCLUDED_KEYS } from './constants';

interface INormalizePostingResponseProps {
  postingResponse: unknown;
  wfType: string;
}

const transformObjectToPrettyStringFormat = (
  transformedResponse: Record<string, unknown>,
  wfType = '',
): string => {
  return `, ${Object.entries(transformedResponse)
    .filter(([key], index) => {
      if (wfType === WfType.BULK_MANUAL_TRANSACTION) {
        return !EXCLUDED_KEYS.includes(key);
      }

      return index < 2;
    })
    .map(([key, value]) => {
      return `${[key]}: ${value}`;
    })
    .join(', ')}`;
};

export const normalizePostingResponse = ({
  postingResponse,
  wfType,
}: INormalizePostingResponseProps): string => {
  if (typeof postingResponse !== 'string') {
    return '';
  }

  let transformedResponse:
    | Record<string, unknown>
    | Array<Record<string, unknown>>;
  const responseToParse = postingResponse
    .replaceAll(' ', '')
    .replaceAll("'", '"');

  try {
    transformedResponse = JSON.parse(responseToParse);

    if (Array.isArray(transformedResponse)) {
      return (transformedResponse as Array<Record<string, unknown>>)
        .map((item: Record<string, unknown>) =>
          transformObjectToPrettyStringFormat(item, wfType),
        )
        .join('');
    }

    return transformObjectToPrettyStringFormat(
      transformedResponse as Record<string, unknown>,
    );
  } catch (err) {
    return postingResponse;
  }
};
