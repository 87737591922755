import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { IconButton } from 'react-ui-kit-exante';

import { WfType, Statuses } from 'constants/index';
import { IExecutionDetails } from 'types/executionDetails';

import { ActionsContainer } from './styled';

interface IActionsApproveDeclineProps {
  data: IExecutionDetails;
  onDeclineExecution: (value: string) => void;
  onApproveExecution: (value: string) => void;
}

export const ActionsApproveDecline: FC<IActionsApproveDeclineProps> = ({
  onDeclineExecution,
  onApproveExecution,
  data,
}) => {
  const { executionId } = useParams<{ executionId: string }>();
  const wfType = data.wf_type;

  if (!executionId) {
    return null;
  }

  const isShow =
    data.status === Statuses.SUSPENDED &&
    (wfType === WfType.MANUAL_TRANSACTION ||
      wfType === WfType.MANUAL_TRADE ||
      wfType === WfType.MANUAL_CHARGE_FEE ||
      wfType === WfType.BULK_MANUAL_TRANSACTION ||
      wfType === WfType.FEE_SETUP ||
      wfType === WfType.FEE_SETUP_PIPELINE ||
      wfType === WfType.FEE_SETUP_GET ||
      wfType === WfType.FEE_SETUP_USER ||
      wfType === WfType.FEE_SETUP_GET_USER);

  return isShow ? (
    <ActionsContainer>
      <IconButton
        iconColor="action"
        iconName="ApproveIcon"
        iconSize={28}
        label="Approve"
        onClick={() => onApproveExecution(executionId)}
      />
      <IconButton
        iconColor="radical"
        iconName="DeclineIcon"
        iconSize={28}
        label="Decline"
        onClick={() => onDeclineExecution(executionId)}
      />
    </ActionsContainer>
  ) : null;
};
