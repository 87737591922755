export enum ROUTES {
  APPROVALS = '/wfe/approvals',
  EXECUTIONS = '/wfe/executions',
  APPROVALS_DETAILS = '/wfe/approvals/:executionId',
  EXECUTIONS_DETAILS = '/wfe/executions/:executionId',
  WORKFLOWS = '/wfe/list',
  WORKFLOW_DETAILS = '/wfe/list/:hashSum',
  HISTORY = '/wfe/history',
  APPROVALS_GROUP = '/wfe/approvals-group',
  ADD_GROUP = '/wfe/approvals-group/add-group',
  ADD_SETTINGS = '/wfe/approvals-group/add-settings',
}
