import { IApprovalGroup, ICreateApprovalGroupPayload } from 'types';
import { apiRequest } from 'utils';

import { APPROVAL_GROUP_API, getApprovalGroupById } from './endpoints';

export class ApprovalGroupRepository {
  public static fetchApprovalGroups() {
    return apiRequest<IApprovalGroup[]>({
      url: APPROVAL_GROUP_API,
    });
  }

  public static updateApprovalGroup(payload: IApprovalGroup) {
    return apiRequest<IApprovalGroup>({
      url: APPROVAL_GROUP_API,
      method: 'PUT',
      data: payload,
    });
  }

  public static deleteApprovalGroup(id: string) {
    return apiRequest({
      url: getApprovalGroupById(id),
      method: 'DELETE',
    });
  }

  public static createApprovalGroup(payload: ICreateApprovalGroupPayload) {
    return apiRequest<IApprovalGroup>({
      url: APPROVAL_GROUP_API,
      method: 'POST',
      data: payload,
    });
  }
}
