import { apiRequest } from 'utils';

import { CURRENCY_API } from './endpoints';
import { ICurrencyProps } from './types';

export class CurrencyRepository {
  public static fetchCurrencies() {
    return apiRequest<ICurrencyProps>({
      url: CURRENCY_API,
    });
  }
}
