import { Panel } from 'react-ui-kit-exante';

import { IExecutionDetails } from 'types';

interface IFeeSetupProps {
  data: IExecutionDetails;
}

export const FeeSetup = ({ data }: IFeeSetupProps) => {
  const { info } = data;
  const [infoData] = info;
  const { setup_owner_doc: setupOwnerDoc } = infoData;
  const { title, url } = setupOwnerDoc;

  if (!title || !url) {
    return <Panel>No file to display</Panel>;
  }

  return <iframe src={`${url}#zoom=65%`} title={title} height="800px" />;
};
