import { IExecutionInfo } from 'types';

export const DISPLAYED_COLUMN_KEYS: Array<keyof IExecutionInfo> = [
  'asset',
  'amount',
  'price',
  'accountId',
  'operationType',
  'comment',
  'internalComment',
  'useAutoCashConversion',
];
export const PAGE_SIZE = 50;
export const PAGE_SIZES = [20, 50, 100];
