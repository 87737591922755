import { Notification } from 'react-ui-kit-exante';

import { DEFAULT_ERROR_MESSAGE } from 'constants/common';
import { formatErrorForKitNotification } from 'utils/formatters';

import { transformErrorToInfoObject } from './transformErrorToInfoObject';

export function sendUiKitErrorNotification(
  error: unknown,
  notificationIsPermanent = false,
) {
  if (!Notification) {
    return;
  }

  const errorInfo = transformErrorToInfoObject(error);

  if (!errorInfo || (!errorInfo.message && !errorInfo.statusCode)) {
    Notification.error({
      title: DEFAULT_ERROR_MESSAGE,
    });

    return;
  }

  const [title, description] = formatErrorForKitNotification(errorInfo);

  Notification.error({
    title,
    description,
    isPermanent: notificationIsPermanent,
  });
}
