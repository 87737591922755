import { IApprovalGroup } from 'types';

export const DISPLAYED_COLUMN_KEYS: Array<keyof IApprovalGroup> = [
  'id',
  'name',
  'description',
  'actions',
];
export const PAGE_SIZE = 20;
export const PAGE_SIZES = [10, 20, 50];

export enum FieldName {
  NAME = 'name',
  DESCRIPTION = 'description',
}
