import { Notification } from 'react-ui-kit-exante';

import { IApprovalGroup, ICreateApprovalGroupPayload } from 'types';
import { getErrorMessage, sendUiKitErrorNotification } from 'utils';

import { ApprovalGroupRepository } from './approvalGroup.repository';

export class ApprovalGroupService {
  public async fetchApprovalGroups(): Promise<IApprovalGroup[]> {
    try {
      const { data } = await ApprovalGroupRepository.fetchApprovalGroups();

      return data?.reverse();
    } catch (err) {
      Notification.error({
        title: getErrorMessage(
          err,
          'While fetching approvals group occurred error',
        ),
      });

      return [];
    }
  }

  public async updateApprovalGroup(
    payload: IApprovalGroup,
  ): Promise<IApprovalGroup | null> {
    try {
      const { data } = await ApprovalGroupRepository.updateApprovalGroup(
        payload,
      );

      Notification.success({
        title: 'Approval Group successfully updated',
      });

      return data;
    } catch (err) {
      sendUiKitErrorNotification(err);

      return null;
    }
  }

  public async deleteApprovalGroup(id: string): Promise<void> {
    try {
      await ApprovalGroupRepository.deleteApprovalGroup(id);

      Notification.success({
        title: 'Approval Group successfully deleted',
      });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }

  public async createApprovalGroup(
    payload: ICreateApprovalGroupPayload,
  ): Promise<void> {
    try {
      await ApprovalGroupRepository.createApprovalGroup(payload);

      Notification.success({
        title: 'Approval Group successfully created',
      });
    } catch (err) {
      Notification.error({
        title: getErrorMessage(
          err,
          'While creating approval group occurred error',
        ),
      });
    }
  }
}
