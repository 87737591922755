import { FC } from 'react';

import { ColorInfoBlock } from 'components/ColorInfoBlock';
import { WfType, Statuses, CommonStatuses, REGEX } from 'constants/index';
import { IExecutionDetails } from 'types';

import { SYSTEM_AUTHOR } from './constants';
import { normalizePostingResponse } from './normalizePostingResponse';

interface IInfoNotificationsProps {
  data: IExecutionDetails;
  onRestartWorkflow?: () => void;
}

export const InfoNotifications: FC<IInfoNotificationsProps> = ({
  data,
  onRestartWorkflow,
}) => {
  const {
    current_state: currentState,
    decision_author: decisionAuthor,
    fail_reason: failReason,
    status,
    wf_type: wfType,
  } = data;
  const failReasonNormalized = failReason?.replaceAll(REGEX, '');
  const isDeclined = failReasonNormalized?.split(' ')[0] === 'Declined';

  const postingResponse = currentState[0].posting_response;
  const normalizedPostingResponse = normalizePostingResponse({
    postingResponse,
    wfType,
  });
  const isTypeInfo = status === Statuses.FAILED ? true : isDeclined;

  let info = isDeclined
    ? `Workflow was already declined by ${decisionAuthor}`
    : `Workflow was already approved by ${decisionAuthor}${normalizedPostingResponse}`;

  if (wfType === WfType.BULK_MANUAL_TRANSACTION && !isDeclined) {
    info = `Workflow was already approved by ${decisionAuthor}`;
  } else if (isDeclined && decisionAuthor === SYSTEM_AUTHOR) {
    info =
      'Workflow was automatically declined due to "Auto Decline In" settings';
  }

  const getSummaryInfo = () => {
    if (status === Statuses.FAILED) {
      return failReasonNormalized;
    }

    if (decisionAuthor || wfType === WfType.FEE_SETUP) {
      return info;
    }

    return normalizedPostingResponse.replace(',', '');
  };

  const getIsShowInfo = () => {
    if (
      status === Statuses.SUCCEED &&
      wfType === WfType.BULK_MANUAL_TRANSACTION &&
      !decisionAuthor
    ) {
      return false;
    }

    if (status === Statuses.SUCCEED) {
      return (
        wfType === WfType.MANUAL_TRANSACTION ||
        wfType === WfType.MANUAL_TRADE ||
        wfType === WfType.MANUAL_CHARGE_FEE ||
        wfType === WfType.BULK_MANUAL_TRANSACTION ||
        wfType === WfType.FEE_SETUP ||
        wfType === WfType.FEE_SETUP_USER
      );
    }

    return !!(status === Statuses.FAILED && failReasonNormalized);
  };

  const isShowInfo = getIsShowInfo();
  const summaryInfo = getSummaryInfo();

  const renderColorInfoBlock = () =>
    isShowInfo ? (
      <ColorInfoBlock
        onRestartWorkflow={onRestartWorkflow}
        status={status}
        text={summaryInfo}
        type={isTypeInfo ? CommonStatuses.ERROR : CommonStatuses.SUCCESS}
      />
    ) : null;

  return renderColorInfoBlock();
};
