import type { TSorting, TSortingForAPI } from 'types';

export const transformSorting = (sorting: TSorting[]): TSortingForAPI => {
  return sorting.reduce((acc, curr) => {
    if (curr.desc === undefined) {
      return acc;
    }

    const direction = curr?.desc ? 'desc' : 'asc';

    return { order: direction, sorted_by: curr.id };
  }, {} as TSortingForAPI);
};
