import { useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

import { Container, LinkStyled, Version } from './styled';

const Header = () => {
  const location = useLocation();
  const isInsideRun = !!window.runUIhistoryContainer;
  const pageName = location.pathname.split('/')[2];

  return !isInsideRun ? (
    <Container>
      <div>
        <div>Workflow UI</div>
        <Version>{`version ${process?.env?.REACT_APP_VERSION}`}</Version>
      </div>
      <div>
        <LinkStyled
          to={ROUTES.APPROVALS}
          pageName={pageName === ROUTES.APPROVALS.split('/')[2]}
        >
          Approvals
        </LinkStyled>
        <LinkStyled
          to={ROUTES.EXECUTIONS}
          pageName={pageName === ROUTES.EXECUTIONS.split('/')[2]}
        >
          Executions
        </LinkStyled>
        <LinkStyled
          to={ROUTES.WORKFLOWS}
          pageName={pageName === ROUTES.WORKFLOWS.split('/')[2]}
        >
          List
        </LinkStyled>
        <LinkStyled
          to={ROUTES.HISTORY}
          pageName={pageName === ROUTES.HISTORY.split('/')[2]}
        >
          History
        </LinkStyled>
        <LinkStyled
          to={ROUTES.APPROVALS_GROUP}
          pageName={pageName === ROUTES.APPROVALS_GROUP.split('/')[2]}
        >
          Approvals Group
        </LinkStyled>
      </div>
    </Container>
  ) : null;
};

export default Header;
