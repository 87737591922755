import { Notification } from 'react-ui-kit-exante';

import type { TParams, TUpdateWorkflowParams } from 'types';
import { getErrorMessage } from 'utils';

import { WorkflowParamsRepository } from './workflow-params.repository';

export class WorkflowParamsService {
  public async updateWorkflowParams(
    params: TParams,
    payload: TUpdateWorkflowParams,
  ) {
    try {
      const { data } = await WorkflowParamsRepository.updateWorkflowParams(
        params,
        payload,
      );

      Notification.success({
        title: '"Auto decline in" successfully updated',
      });

      return data;
    } catch (err) {
      Notification.error({
        title: getErrorMessage(
          err,
          'While updating workflows params occurred error',
        ),
      });

      return {};
    }
  }
}
