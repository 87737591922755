import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { App } from './App';
import { initResources } from './resources';
import { store } from './store';
import { ComposedThemeProvider } from './theme';
import { addBaseTag } from './utils';

addBaseTag();
initResources();

const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('workflow-ui');

  if (!rootNode) {
    throw new Error('Root element was not found');
  }

  const root = createRoot(rootNode);

  window.WORKFLOW_UI = {
    ...(window.WORKFLOW_UI || {}),
    root,
  };

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ComposedThemeProvider>
          <App />
        </ComposedThemeProvider>
      </Provider>
    </React.StrictMode>,
  );
};

window.WORKFLOW_UI = {
  ...(window.WORKFLOW_UI || {}),
  render: (node: Element) => initApp(node),
};

// // Case when rendered not inside run-ui
if (!window.runUIhistoryContainer) {
  initApp();
}
