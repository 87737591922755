import { Table } from 'react-ui-kit-exante';

import { EMPTY_ARRAY } from 'constants/common';

import {
  DEFAULT_SORTING,
  DISPLAYED_COLUMN_KEYS,
  PAGE_SIZE,
  PAGE_SIZES,
} from './constants';
import { useExecutionsPage } from './hooks';

export const ExecutionsPage = () => {
  const {
    columns,
    data,
    filteringProps,
    isLoading,
    getRowProps,
    handleCellClick,
    serverPaginationProps,
    setSorting,
    tableId,
  } = useExecutionsPage();

  const isInitialLoading = !data && isLoading;

  return (
    <Table
      columns={columns}
      data={data?.results || EMPTY_ARRAY}
      defaultSortBy={DEFAULT_SORTING}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      filteringProps={filteringProps}
      getRowProps={getRowProps}
      handleCellClick={handleCellClick}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isInitialLoading}
      manualSortBy
      onSort={setSorting}
      pageSize={PAGE_SIZE}
      pageSizes={PAGE_SIZES}
      saveColumnOrder
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      showTableInfo
      showScrollbar
      tableId={tableId}
      title="Executions"
    />
  );
};
