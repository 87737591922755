import { SESSION_ID_BACK_OFFICE_COOKIE_KEY } from 'constants/session';
import type { TParams } from 'types';
import { apiRequest } from 'utils';

import { defaultParams } from './constants';
import { USERS_API } from './endpoints';

export class UsersRepository {
  public static fetchUsers(params: TParams = defaultParams) {
    return apiRequest({
      params,
      url: USERS_API,
      cookieType: SESSION_ID_BACK_OFFICE_COOKIE_KEY,
    });
  }
}
