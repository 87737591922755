import { useMemo } from 'react';
import { Table } from 'react-ui-kit-exante';

import { RefreshButton } from 'components/RefreshButton';
import { EMPTY_ARRAY } from 'constants/common';

import { MarketValue } from './MarketValue';
import {
  DEFAULT_SORTING,
  DISPLAYED_COLUMN_KEYS,
  PAGE_SIZE,
  PAGE_SIZES,
} from './constants';
import { useApprovalsPage } from './hooks';

export const ApprovalsPage = () => {
  const {
    bulkActions,
    columns,
    data,
    filteringProps,
    getRowProps,
    handleClickOnRefresh,
    handleCellClick,
    isLoading,
    isRefreshBtnClicked,
    marketValue,
    onActionLoading,
    serverPaginationProps,
    setSorting,
    tableId,
  } = useApprovalsPage();

  const additionalActions = useMemo(
    () => [
      {
        key: 'RefreshPage',
        component: (
          <RefreshButton loading={isLoading} onRefresh={handleClickOnRefresh} />
        ),
      },
    ],
    [handleClickOnRefresh, isLoading],
  );

  const isInitialLoading = !data && isLoading;

  return (
    <Table
      additionalActions={additionalActions}
      bulkActions={bulkActions}
      columns={columns}
      commonValue={{ label: 'Selected Market Value', value: marketValue }}
      customHeadComponent={<MarketValue />}
      data={data?.results || EMPTY_ARRAY}
      defaultSortBy={DEFAULT_SORTING}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      filteringProps={filteringProps}
      getRowProps={getRowProps}
      handleCellClick={handleCellClick}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isInitialLoading || onActionLoading || isRefreshBtnClicked}
      manualSortBy
      onSort={setSorting}
      pageSize={PAGE_SIZE}
      pageSizes={PAGE_SIZES}
      saveColumnOrder
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      showTableInfo
      showScrollbar
      tableId={tableId}
      title="Approvals"
    />
  );
};
