import { createApi } from '@reduxjs/toolkit/query/react';

import { accumMarketValueService } from 'resources';
import { transformToBaseQueryResponse } from 'utils';

export const accumMarketValueApi = createApi({
  reducerPath: 'accumMarketValueQuery',
  tagTypes: ['AccumMarketValue'],
  baseQuery: async (args) =>
    transformToBaseQueryResponse(
      await accumMarketValueService.fetchMarketValue(args),
    ),
  endpoints: (build) => ({
    getAccumMarketValue: build.query({
      query: (params) => params,
      providesTags: ['AccumMarketValue'],
    }),
  }),
});

export const { useGetAccumMarketValueQuery } = accumMarketValueApi;
