import { useMemo } from 'react';
import { Skeleton, Table, Panel } from 'react-ui-kit-exante';

import { prepareTableId } from 'utils';

import { TableWrapper, Wrapper } from '../ExecutionContainer/styled';

import { getColumns } from './columns';
import { DISPLAYED_COLUMN_KEYS, PAGE_SIZE, PAGE_SIZES } from './constants';

const tableId = prepareTableId('bulkTransactionsSkeleton');

export const BulkTransactionsSkeleton = () => {
  const columns = useMemo(() => getColumns(), []);

  return (
    <>
      <Panel
        title={<Skeleton variant="text" width={350} height={50} />}
        action={<Skeleton variant="rounded" width={250} height={53} />}
      />
      <Wrapper>
        <TableWrapper>
          <Table
            isLoading
            data={[]}
            isFlexLayout
            showTableInfo
            hasPagination
            pageSize={PAGE_SIZE}
            columns={columns}
            tableId={tableId}
            pageSizes={PAGE_SIZES}
            displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
          />
        </TableWrapper>
      </Wrapper>
    </>
  );
};
