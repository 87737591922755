import { WfType } from 'constants/index';
import { IExecutionDetails, IExecutionInfo, TInfoCombined } from 'types';

import {
  CURRENT_STATE_DATE_VALUES,
  CURRENT_STATE_NUMBER_VALUES,
  FEE_SETUP_GET,
  FEE_SETUP_KEYS,
  FEE_SETUP_USER,
  INFO_DATE_VALUES,
  INFO_NUMBER_MAX_FRACTION_DIGITS_VALUES,
  INFO_NUMBER_VALUES,
  INPUT_DATE_VALUES,
  INPUT_NUMBER_VALUES,
  TRADE_KEYS,
  TRANSACTION_CHARGE_FEE_KEYS,
} from '../constants';

import { transformKeys } from './transformKeys';
import { transformValues } from './transformValues';

interface IGetInfoProps {
  data: IExecutionDetails;
}

interface IGetInfoResponseProps {
  formattedInfo: TInfoCombined;
  formattedInput: TInfoCombined;
  schedule: string;
}

export const getInfo = ({ data }: IGetInfoProps): IGetInfoResponseProps => {
  let includedKeys;
  const { info, infoCombined, input, wf_type: wfType } = data;

  const [mainInfo] = info;
  const [mainInput] = input;

  switch (wfType) {
    case WfType.MANUAL_TRADE:
      includedKeys = TRADE_KEYS;
      break;
    case WfType.FEE_SETUP:
    case WfType.FEE_SETUP_NEW_SETUPS:
    case WfType.FEE_SETUP_PIPELINE:
      includedKeys = FEE_SETUP_KEYS;
      break;
    case WfType.FEE_SETUP_GET:
    case WfType.FEE_SETUP_GET_USER:
      includedKeys = FEE_SETUP_GET;
      break;
    case WfType.FEE_SETUP_USER:
      includedKeys = FEE_SETUP_USER;
      break;
    default:
      includedKeys = TRANSACTION_CHARGE_FEE_KEYS;
  }

  const transformedInfo = transformValues({
    data: mainInfo || ({} as IExecutionInfo | TInfoCombined),
    dateValues: INFO_DATE_VALUES,
    numberValues: INFO_NUMBER_VALUES,
    numberMaxFractionDigitsValues: INFO_NUMBER_MAX_FRACTION_DIGITS_VALUES,
  });
  const transformedInfoAndCurrentState = transformValues({
    data: infoCombined || ({} as IExecutionInfo | TInfoCombined),
    dateValues: [...INFO_DATE_VALUES, ...CURRENT_STATE_DATE_VALUES],
    numberValues: [...INFO_NUMBER_VALUES, ...CURRENT_STATE_NUMBER_VALUES],
    numberMaxFractionDigitsValues: [
      ...INFO_NUMBER_MAX_FRACTION_DIGITS_VALUES,
      ...CURRENT_STATE_NUMBER_VALUES,
    ],
  });
  const transformedInput = transformValues({
    data: mainInput || ({} as IExecutionInfo | TInfoCombined),
    dateValues: INPUT_DATE_VALUES,
    numberValues: INPUT_NUMBER_VALUES,
  });

  const { transformedData, schedule } = transformKeys(
    transformedInfoAndCurrentState,
    includedKeys,
  );
  const { transformedData: transformedInputData } = transformKeys(
    transformedInput,
    includedKeys,
  );

  const isWfType =
    wfType === WfType.MANUAL_TRADE ||
    wfType === WfType.MANUAL_TRANSACTION ||
    wfType === WfType.MANUAL_CHARGE_FEE ||
    wfType === WfType.FEE_SETUP ||
    wfType === WfType.FEE_SETUP_NEW_SETUPS ||
    wfType === WfType.FEE_SETUP_PIPELINE ||
    wfType === WfType.FEE_SETUP_GET ||
    wfType === WfType.FEE_SETUP_USER ||
    wfType === WfType.FEE_SETUP_GET_USER;

  return {
    formattedInfo: isWfType ? transformedData : transformedInfo,
    formattedInput: transformedInputData,
    schedule: schedule || '',
  };
};
