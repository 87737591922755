import { DAY_IN_SECONDS } from 'constants/common';
import type { TWorkflow } from 'types';

export function workflowMappers(workflow: TWorkflow[]) {
  return workflow.map((item) => {
    const { name, wf_type: wfType } = item;

    return {
      value: wfType,
      label: name,
    };
  });
}

export function workflowListMappers(workflow: TWorkflow[]) {
  return workflow.reverse().map((item) => {
    const { ttl } = item;

    return {
      ...item,
      ttl: ttl / DAY_IN_SECONDS,
    };
  });
}
