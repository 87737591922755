import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { IconButton, Input, Panel } from 'react-ui-kit-exante';

import { ROUTES } from 'constants/routes';
import { approvalGroupService } from 'resources';
import { ICreateApprovalGroupPayload } from 'types';

import { controlCls, defaultValues } from './constants';
import { ActionsContainer, ControlsContainer } from './styled';
import { validationSchema } from './validationSchema';

export const AddApprovalGroupPage = () => {
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(ROUTES.APPROVALS_GROUP);
  }, [navigate]);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
  } = methods;

  const onSubmit = async (payload: ICreateApprovalGroupPayload) => {
    await approvalGroupService.createApprovalGroup(payload);
    handleClose();
  };

  const renderActions = () => (
    <ActionsContainer>
      <IconButton
        disabled={!isDirty}
        iconColor="action"
        iconName="SaveIcon"
        iconSize={24}
        label="Save"
        type="submit"
      />
      <IconButton
        iconColor="secondary"
        iconName="CloseIcon"
        iconSize={24}
        onClick={handleClose}
      />
    </ActionsContainer>
  );

  const controls = (
    <ControlsContainer>
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            label="Name"
            sx={controlCls}
            warning={dirtyFields.name}
            error={Boolean(errors.name)}
            message={errors.name?.message}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            sx={controlCls}
            label="Description"
            warning={dirtyFields.description}
            error={Boolean(errors.description)}
            message={errors.description?.message}
          />
        )}
      />
    </ControlsContainer>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Panel title="Create Approval group" action={renderActions()} />
      {controls}
    </form>
  );
};
