import { ICellValue, IColumn, formatDate } from 'react-ui-kit-exante';

import { IExecutionDetailsSteps } from 'types';

export const getColumns = (): IColumn<IExecutionDetailsSteps>[] => [
  {
    Header: 'Step Group',
    accessor: 'stepGroup',
    width: 165,
    Cell: ({ row }: ICellValue<IExecutionDetailsSteps>) => {
      const { values } = row;

      return values?.stepGroup || values?.step_id;
    },
  },
  {
    Header: 'Author',
    accessor: 'author',
  },
  {
    Header: 'Created at',
    accessor: 'create_time',
    formatting: 'date',
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string | null) => {
          if (!value) {
            return '-';
          }

          return formatDate({
            date: value,
            withTime: true,
          });
        },
      },
    },
    width: 85,
  },
  {
    Header: 'Updated at',
    accessor: 'update_time',
    formatting: 'date',
    tooltip: {
      type: 'custom',
      options: {
        Title: (value: string | null) => {
          if (!value) {
            return '-';
          }

          return formatDate({
            date: value,
            withTime: true,
          });
        },
      },
    },
    width: 85,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 80,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    width: 100,
    editParams: {
      editable: true,
      inputType: 'text',
    },
    type: 'textInput',
  },
  {
    Header: 'Step ID',
    accessor: 'step_id',
    hideInMenu: true,
  },
];
