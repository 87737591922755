import { useEffect, useState } from 'react';
import { formatNumber, Skeleton } from 'react-ui-kit-exante';

import { DEFAULT_POOLING_INTERVAL_DATA_20 } from 'constants/common';
import { useInterval } from 'hooks';
import { accumMarketValueService } from 'resources';
import { TAccumulationMarketValue } from 'types';

import { LabelContainer, ValueContainer, Wrapper } from './styled';

export const MarketValue = () => {
  const [marketValue, setMarketValue] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchMarketData = async () => {
    setIsLoading(true);
    const response = await accumMarketValueService.fetchMarketValue(
      {
        workflow_execution_ids: [],
      },
      setIsError,
    );
    setIsLoading(false);
    setMarketValue((response as TAccumulationMarketValue).market_value);
  };
  const clearInterval = useInterval(
    () => fetchMarketData(),
    DEFAULT_POOLING_INTERVAL_DATA_20,
  );

  useEffect(() => {
    if (isError) {
      clearInterval();
    }
  }, [isError]);

  return (
    <Wrapper>
      <ValueContainer>
        {isLoading ? (
          <Skeleton
            width={140}
            height={20}
            variant="rounded"
            sx={{ marginBottom: '3px' }}
          />
        ) : (
          formatNumber(marketValue || 0)
        )}
      </ValueContainer>
      <LabelContainer>Market Value, EUR</LabelContainer>
    </Wrapper>
  );
};
