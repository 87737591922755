import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { IApprovalSetting, IOption } from 'types';

export const getColumns = (
  groupOptions: IOption[],
  usersOptions: IOption[],
): IColumn<IApprovalSetting>[] => [
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Approval Group',
    accessor: 'approvalGroup',
    editParams: {
      editable: true,
      inputType: 'select',
      options: groupOptions,
    },
  },
  {
    Header: 'Approval Threshold',
    accessor: 'approvalThreshold',
    formatting: 'number',
    align: 'left',
    editParams: {
      editable: true,
      inputType: 'text',
    },
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    editParams: {
      editable: false,
    },
  },
  {
    Header: 'Approvers',
    accessor: 'approvers',
    editParams: {
      editable: true,
      inputType: 'multiSelect',
      options: usersOptions,
    },
    Cell: ({ row }: ICellValue<IApprovalSetting>) => {
      const approvers = row.values?.approvers
        ?.filter((item: string) => item !== '-')
        .join()
        .replace(/,/g, ', ');

      return <span style={{ whiteSpace: 'pre-wrap' }}>{approvers}</span>;
    },
  },
  {
    Header: 'Emails',
    accessor: 'emails',
    editParams: {
      editable: true,
      inputType: 'textArea',
    },
    Cell: ({ row }: ICellValue<IApprovalSetting>) => {
      return (
        <span style={{ whiteSpace: 'pre-wrap' }}>
          {row.values?.emails?.join(',').replace(/,/g, ', ')}
        </span>
      );
    },
  },
];
