import { createApi } from '@reduxjs/toolkit/query/react';

import { EMPTY_FUNCTION } from 'constants/common';
import { usersService } from 'resources';
import { transformToBaseQueryResponse } from 'utils';

export const usersApi = createApi({
  reducerPath: 'usersQuery',
  tagTypes: ['Users'],
  baseQuery: async () =>
    transformToBaseQueryResponse(await usersService.fetchUsers()),
  endpoints: (build) => ({
    getUsers: build.query({
      query: EMPTY_FUNCTION,
      providesTags: ['Users'],
    }),
  }),
});

export const { useGetUsersQuery } = usersApi;
