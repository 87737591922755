import { Notification } from 'react-ui-kit-exante';

import { IApprovalSetting, ICreateApprovalSettingPayload } from 'types';
import { getErrorMessage, sendUiKitErrorNotification } from 'utils';

import { ApprovalSettingRepository } from './approvalSetting.repository';

export class ApprovalSettingService {
  public async fetchApprovalSettings(): Promise<IApprovalSetting[]> {
    try {
      const { data } = await ApprovalSettingRepository.fetchApprovalSettings();

      return data?.reverse();
    } catch (err) {
      Notification.error({
        title: getErrorMessage(
          err,
          'While fetching approvals setting occurred error',
        ),
      });

      return [];
    }
  }

  public async updateApprovalSetting(
    payload: IApprovalSetting,
  ): Promise<IApprovalSetting | null> {
    try {
      const { data } = await ApprovalSettingRepository.updateApprovalSetting(
        payload,
      );

      Notification.success({
        title: 'Approval Setting successfully updated',
      });

      return data;
    } catch (err) {
      Notification.error({
        title: getErrorMessage(
          err,
          'While updating approval setting occurred error',
        ),
      });

      return null;
    }
  }

  public async deleteApprovalSetting(id: string): Promise<void> {
    try {
      await ApprovalSettingRepository.deleteApprovalSetting(id);

      Notification.success({
        title: 'Approval Setting successfully deleted',
      });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }

  public async createApprovalSetting(
    payload: ICreateApprovalSettingPayload,
  ): Promise<void> {
    try {
      await ApprovalSettingRepository.createApprovalSetting(payload);

      Notification.success({
        title: 'Approval Setting successfully created',
      });
    } catch (err) {
      sendUiKitErrorNotification(err);
    }
  }
}
