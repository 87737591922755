import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useLayoutEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Router, BrowserRouter, Route, Routes } from 'react-router-dom';
import { GlobalStyles, NotificationProvider } from 'react-ui-kit-exante';

import { ErrorFallback } from 'components/ErrorFallback';
import Header from 'components/Header';
import { ROUTES } from 'constants/routes';
import useStoreRequests from 'hooks/useStoreRequests';
import {
  WorkflowDetailsPage,
  ApprovalsPage,
  ExecutionDetailsPage,
  ExecutionsPage,
  HistoryPage,
  WorkflowListPage,
  ApprovalsGroupPage,
  AddApprovalGroupPage,
  AddApprovalSettingPage,
} from 'pages';
import { getBaseName } from 'utils';

import 'styles/Global.css';

dayjs.extend(utc);

export const App = () => {
  useStoreRequests();

  const history = window.runUIhistoryContainer;

  const [navigator, setNavigator] = useState({
    action: history?.action,
    location: history?.location,
  });

  const isInsideRun = !!history;
  const baseName = getBaseName();

  const routes = (
    <Routes>
      <Route path={`${ROUTES.APPROVALS}/*`} element={<ApprovalsPage />} />
      <Route
        path={ROUTES.APPROVALS_DETAILS}
        element={<ExecutionDetailsPage />}
      />
      <Route path={`${ROUTES.EXECUTIONS}/*`} element={<ExecutionsPage />} />
      <Route
        path={ROUTES.EXECUTIONS_DETAILS}
        element={<ExecutionDetailsPage />}
      />
      <Route path={`${ROUTES.WORKFLOWS}/*`} element={<WorkflowListPage />} />
      <Route path={ROUTES.WORKFLOW_DETAILS} element={<WorkflowDetailsPage />} />
      <Route path={ROUTES.HISTORY} element={<HistoryPage />} />
      <Route
        path={`${ROUTES.APPROVALS_GROUP}/*`}
        element={<ApprovalsGroupPage />}
      />
      <Route path={ROUTES.ADD_GROUP} element={<AddApprovalGroupPage />} />
      <Route path={ROUTES.ADD_SETTINGS} element={<AddApprovalSettingPage />} />
    </Routes>
  );

  useLayoutEffect(() => {
    if (history) {
      history.listen(() => {
        return setNavigator({
          action: history.action,
          location: history.location,
        });
      });
    }
  }, [history]);

  return (
    <>
      <NotificationProvider />
      {isInsideRun ? (
        <Router
          location={navigator.location}
          navigationType={navigator.action}
          navigator={history}
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <GlobalStyles />
            {routes}
          </ErrorBoundary>
        </Router>
      ) : (
        <BrowserRouter basename={baseName}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <GlobalStyles />
            <Header />
            {routes}
          </ErrorBoundary>
        </BrowserRouter>
      )}
    </>
  );
};
