import { Panel, styled } from 'react-ui-kit-exante';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: `1px ${theme.color.input.border} solid`,
}));

export const TableWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
}));

export const SchedulerBlock = styled(Panel)(({ theme }) => ({
  borderBottom: `1px ${theme.color.input.border} solid`,
  marginTop: 0,
  width: '100%',

  '& h2': {
    fontSize: '32px',
    marginTop: 0,
  },
}));
