import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchWorkflow } from 'store/workflow';

export default function useStoreRequests() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWorkflow());
  }, []);
}
