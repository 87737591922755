import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, useTableData } from 'react-ui-kit-exante';

import { ROUTES } from 'constants/routes';
import { useLogHandleTime, usePrevious } from 'hooks';
import { approvalSettingService } from 'resources';
import { IApprovalGroup, IApprovalSetting, IOption } from 'types';
import { prepareTableId } from 'utils';

import { getColumns } from './columns';
import { DISPLAYED_COLUMN_KEYS, PAGE_SIZE, PAGE_SIZES } from './constants';
import { isEmptyField } from './helpers';
import { TableContainer } from './styled';

const tableId = prepareTableId('approvalSetting');

interface ISettingsProps {
  groupData: IApprovalGroup[] | null;
  usersOptions: IOption[];
}

export const Settings = ({ groupData, usersOptions }: ISettingsProps) => {
  const navigate = useNavigate();

  const { logHandleTime, setStartHandleTime } = useLogHandleTime(
    'approval-setting-page',
  );

  const getApprovalSettings = useCallback(() => {
    setStartHandleTime();

    return approvalSettingService.fetchApprovalSettings();
  }, [setStartHandleTime]);

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getApprovalSettings },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getApprovalSettings, tableId],
  );

  const {
    data,
    isLoading,
    fetchData: refetch,
  } = useTableData<IApprovalSetting[]>(tableDataArgs);

  const groupOptions =
    groupData?.map((item: { name: string }) => {
      const { name } = item;

      return { value: name, label: name };
    }) || [];

  const columns = useMemo(
    () => getColumns(groupOptions, usersOptions),
    [groupOptions, usersOptions],
  );

  const handleAddApprovalSetting = useCallback(() => {
    navigate(ROUTES.ADD_SETTINGS, {
      state: {
        groupOptions,
        usersOptions,
      },
    });
  }, [navigate, groupOptions, usersOptions]);

  const additionalActions = useMemo(
    () => [
      {
        key: 'AddSetting',
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add setting"
            iconSize={24}
            onClick={handleAddApprovalSetting}
          />
        ),
      },
    ],
    [handleAddApprovalSetting],
  );

  const handleSave = useCallback(
    async (_: IApprovalSetting, values: IApprovalSetting) => {
      const {
        id,
        approvers,
        currency,
        approvalGroup,
        approvalThreshold,
        emails,
      } = values;

      if (isEmptyField(approvers, 'approvers')) {
        await refetch();

        return;
      }

      await approvalSettingService.updateApprovalSetting({
        id,
        approvers,
        currency,
        approvalGroup,
        approvalThreshold,
        emails: Array.isArray(emails)
          ? emails
          : emails?.replace(/,/g, ', ').split(', '),
      });
      await refetch();
    },
    [],
  );

  const handleDelete = useCallback(async (value: IApprovalSetting) => {
    const { id } = value;

    if (id) {
      await approvalSettingService.deleteApprovalSetting(id);
    }

    await refetch();
  }, []);

  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: handleSave,
      additionalActions: [
        {
          label: (
            <IconButton
              iconColor="radical"
              iconName="DeleteIcon"
              iconSize={16}
            />
          ),
          title: 'Delete',
          onClick: handleDelete,
        },
      ],
    }),
    [handleSave, handleDelete],
  );

  const previousSettingData = usePrevious(data);

  useEffect(() => {
    if (data && !isEqual(previousSettingData, data)) {
      logHandleTime();
    }
  }, [data, logHandleTime, previousSettingData]);

  return (
    <TableContainer
      additionalActions={additionalActions}
      columns={columns}
      data={data || []}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      pageSize={PAGE_SIZE}
      pageSizes={PAGE_SIZES}
      rowActions={rowActions}
      saveColumnOrder
      showTableInfo
      tableId={tableId}
      title="Approvals Setting"
    />
  );
};
